import React, { useState, useEffect } from "react"

import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BigHeaderVideo from "../../../components/header/bigHeader/bigHeaderVideo"
import "../../../components/layout/layout.css"
import "../../../components/devicon/devicon.css"
import logo from "../../../../static/logos/logo-telekom.svg"
import Kontakt from "../../../components/kontakt"
import Navigation from '../../../components/projekte/navigation'
import {AnchorLink} from "gatsby-plugin-anchor-links"
import ThumbleisteSwiper from "../../../components/thumbleisteSwiper"
import Liste from "../../../components/liste"
import ListenModule from "../../../components/listenModule"
import myLocalVid from '../../../images/projekte/telekom-glasfaserinfo/t-glasfaserinfo-hero.mp4'
import begruessungVideo from '../../../images/projekte/telekom-glasfaserinfo/01-01-Jonas-small.mp4'
import YouTube from "react-youtube"
import * as projekteStyles from "../projekte.module.css"
import Specs from "../../../components/specs"
import YtVideo from "../../../components/ytvideo"
import MyVideo from "../../../components/video"


const TelekomGlasfaserinfo = function (props, ref) {

    const opts = {
        width: '1180',
        height: '664',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
          rel:0
        }
    }

    const [enableYoutube, setEnableYoutube] = useState(true)

    useEffect(() => {
        setEnableYoutube(localStorage.getItem('enable-youtube'))
    }, [])


    const data = useStaticQuery(graphql`
        query TelekomGlasfaserinfoQuery {

            heropic: file(relativePath: { eq: "images/projekte/telekom-glasfaserinfo/telekom-glasfaserinfo-hero.jpg" }) {
                childImageSharp {
                    gatsbyImageData(          
                        placeholder: BLURRED,
                        layout: FULL_WIDTH,
                        aspectRatio: 1.7
                    )
                  }
            }

            ogimage: file(relativePath: { eq: "images/projekte/telekom-glasfaserinfo/telekom-glasfaserinfo-hero.jpg" }) {
                childImageSharp {
                    fixed(height: 400) {
                        src
                        height
                        width
                    }
                }
            }

            allFile(filter: {extension: {regex: "/(jpg|png)/"}, relativeDirectory: {eq: "images/projekte/telekom-glasfaserinfo/thumbleiste"}}) {
                edges {
                  node {
                    id
                    childImageSharp {
                        picscropped:gatsbyImageData(placeholder: DOMINANT_COLOR, aspectRatio: 1, layout: FULL_WIDTH, formats: [WEBP], transformOptions: {rotate: 0, cropFocus: CENTER})
                        pics:gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH, formats: [WEBP])
                      }
                  }
                }
              }                                     
        }
    `)

    const specsData = [
        { title: "Eckdaten", objects: [logo, "Web App"] },
        { title: "Leistungen", objects: ["konzept", "design", "programmierung"] },
        {
          title: "Toolchain",
          objects: ["figma", "html", "css", "js", "react"],
        },
      ]
  
    return (
        <Layout>
            <SEO
                title="Telekom Glasfaser Infopage-Builder"
                description="Q:marketing realisiert Konfigurator für digitale Infopages zur Vermarktung von Glasfaser für die Telekom."
                image={data?.ogimage?.childImageSharp?.fixed}
            />

            <BigHeaderVideo mode="textFirst" className={`${projekteStyles.child}`} bgVideo={myLocalVid} videoMode="local">
                <div className="row py-5 py-md-0">
                    <div className="col-12 col-md-5">
                        <img src={logo} className="h-40px" />
                        <h1>Telekom Glasfaser Infopage-Builder</h1>                                                
                        <p className="introtext">Konfigurator zur Erstellung von individuellen Präsentationen zur Vermarktung von Glasfaser.</p>
                        <AnchorLink to="/projekte/konfiguratoren/telekom-glasfaser-infopage-builder/#thumbleiste" title="↓ Mehr Erfahren" className="q-btn q-btn-primary" />
                    </div>
                    <div className="col-7"></div>
                </div>
            </BigHeaderVideo> 

            {/* Bilderstrecke Thumbs */}   
            <ThumbleisteSwiper picdata={data.allFile} bgCol="#EFEFE6"/>                                         


            {/* Reasons why */}
            <ListenModule headline="Reasons why" margin={false}>
                <Liste
                    items={[
                        "Geführte und moderierte Vermittlung von Informationen zum Thema Glasfaser",
                        "Erstellung von Infopages im Handumdrehen",
                        "KI-gestützte Generierung von Video- und Audiodaten",                        
                        "Zentrale Steuerung der Inhalte für einen tagesaktuellen Informationsstand"
                    ]}
                />               
            </ListenModule>


            {/* Video */}
            {/* {enableYoutube &&
                <ListenModule headline="Video">
                    <div className={`col`}>
                        <div className="">                            
                            <YouTube
                                videoId="GaW4qwNoEaU"
                                opts={opts}
                            />
                        </div>
                    </div>
                </ListenModule>
            }      */}    

            <ListenModule headline="Teaser">              
                <div className="row">
                    <MyVideo videoSrcURL={begruessungVideo}/>                
                </div>
                <div className="row">
                    <div className="col-12">
                        Beispiel für eine KI-generierte Begrüßung.
                    </div>
                </div>                  
            </ListenModule>                          
           
             
            <Specs data={specsData} />

            {/* navigation */}            
            <Navigation location={props.location} />

            {/* kontakt */}            
            <Kontakt ansprechpartner="juergen-dworak"/>

        </Layout>
    )
}

export default TelekomGlasfaserinfo